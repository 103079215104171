<template>
  <v-container>
    <v-row class="my-3">
      <v-col>
        <v-card flat>
          <v-card-title>Experience</v-card-title>
          <v-timeline dense>
            <v-timeline-item>
              <v-card class="elevation-2">
                <v-card-title>
                  <v-chip outlined color="primary">June 2013 - Present</v-chip>
                </v-card-title>
                <v-card-text>
                  <div class="font-weight-medium title">Technology Specialist</div>
                  <div class="font-weight-medium subtitle-1">TELUS</div>
                  <div>Edmonton, Alberta</div>
                  <p class="font-weight-medium">
                    <br>
                    Systems Administrator for Microsoft SharePoint and Google Workspace.
                    Some of my work is with operational tasks such as monitoring service health, maintaining applications on Windows servers, and supporting corporate team members.
                    I am involved in enterprise level projects that include work with Identity Management, Collaboration tools, and Cloud services.
                    I also maintain and update provisioning scripts using PowerShell and other languages for connecting to Google API, Active Directory, and data sources.
                  </p>
                </v-card-text>
              </v-card>
            </v-timeline-item>
            <v-timeline-item>
              <v-card class="elevation-2">
                <v-card-title>
                  <v-chip outlined color="primary">April 2006 - May 2013</v-chip>
                </v-card-title>
                <v-card-text>
                  <div class="font-weight-medium title">Systems Analyst</div>
                  <div class="font-weight-medium subtitle-1">Alentus</div>
                  <div>Edmonton, Alberta</div>
                  <p class="font-weight-medium">
                    <br>Responsibilities:
                    <br>- Manage and support web servers using IIS and Apache
                    <br>- Manage MySQL and Microsoft SQL Server databases including backup and maintenance
                    <br>- Administer Windows servers and UNIX/Linux servers
                    <br>- Support Email and DNS systems
                    <br>- Install, deploy, and manage SharePoint websites
                    <br>- Troubleshoot web site issues including debugging web scripts, FTP logins, and network issues using traceroute, ping and other network protocols
                    <br>- Installation, backups, and restorations of SSL Certificates for websites
                    <br>- Install server hardware including network cards, hard drives and RAM
                    <br>
                    <br>Accomplishments:
                    <br>- Developed Alentus web order form (PHP + AJAX)
                    <br>- Developed SharePoint web parts with Visual Studio 2010 in C#
                    <br>- Developed Windows Batch and VB scripts for Windows servers
                    <br>- Developed UNIX shell scripts for mail servers and Apache web servers
                    <br>- Email Administration with qmail, postfix, and Barracuda spam filters
                    <br>- Maintained backups for SQL Server, MySQL, SharePoint, and web servers
                  </p>
                </v-card-text>
              </v-card>
            </v-timeline-item>
          </v-timeline>
        </v-card>
      </v-col>
    </v-row>
    <v-divider></v-divider>
    <v-row class="my-3">
      <v-col>
        <v-card flat>
          <v-card-title>Education</v-card-title>
          <v-timeline dense>
            <v-timeline-item>
              <v-card class="elevation-2">
                <v-card-title>
                  <v-chip outlined color="primary">January 2003 - August 2004</v-chip>
                </v-card-title>
                <v-card-text>
                  <div class="font-weight-medium title">Computer Systems Technology Diploma (with Honours)</div>
                  <div class="font-weight-medium subtitle-1">Northern Alberta Institute of Technology</div>
                  <div>Edmonton, Alberta</div>
                  <div class="caption">
                    <v-icon>mdi-license</v-icon>
                    Jason Lang scholarship achieved
                  </div>
                </v-card-text>
              </v-card>
            </v-timeline-item>
            <v-timeline-item>
              <v-card class="elevation-2">
                <v-card-title>
                  <v-chip outlined color="primary">June 2001</v-chip>
                </v-card-title>
                <v-card-text>
                  <div class="font-weight-medium title">Alberta High School Diploma</div>
                  <div class="font-weight-medium subtitle-1">Archbishop MacDonald High School</div>
                  <div>Edmonton, Alberta</div>
                </v-card-text>
              </v-card>
            </v-timeline-item>
          </v-timeline>
        </v-card>
      </v-col>
    </v-row>
    <v-divider></v-divider>
    <v-row class="my-3">
      <v-col>
        <v-card flat>
          <v-card-title>Developer Skills</v-card-title>
          <v-chip
            v-for="developerSkill in developerSkills"
            :key="developerSkill"
            class="ma-3"
            color="primary"
          >
            {{ developerSkill }}
          </v-chip>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'Resume',
  data: () => ({
    developerSkills: [
      'JavaScript',
      'Vue.js',
      'HTML & CSS',
      'Ruby on Rails',
      'Python',
      'PowerShell',
      'Shell script',
      'Microsoft SQL Server',
      'PostgreSQL'
    ]
  })
}
</script>

<style>

</style>

<template>
  <v-container>
    <v-row>
      <v-col>
        <h1 class="text-center">Dat Hong</h1>
      </v-col>
    </v-row>
    <v-row class="my-3">
      <v-col>
        <v-card flat>
          <v-card-title>About Me</v-card-title>
          <v-card-text>
            Information technology professional with 15 years of overall experience.
            As a full-stack web developer, I am skilled at defining, designing, and implementing securely efficient web technologies.
            When managing technical support operations, I can diligently troubleshoot, problem solve, identify root causes and prevent reoccurence.
            While directing IT projects, I have repeated success from inception to completion with planning, designing, delegating tasks, deploying and supporting
            solutions.
          </v-card-text>
          <v-card-actions>
            <v-btn
              :to="{ name: 'Resume' }"
              color="blue"
              outlined
              text
            >
              See Resume
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
    <v-divider></v-divider>
    <v-row class="my-3">
      <v-col>
        <v-card
          class="mx-auto"
          max-width="400"
          elevation="2"
          outlined
        >
          <v-card-title>Contact Information</v-card-title>
          <v-list class="transparent">
            <v-list-item>
              <v-list-item-title>Email</v-list-item-title>

              <v-list-item-icon>
                <v-icon color="blue-grey">mdi-email</v-icon>
              </v-list-item-icon>

              <v-list-item-subtitle class="text-right">
                contact@dathong.work
              </v-list-item-subtitle>
            </v-list-item>
            <v-list-item>
              <v-list-item-title>Location</v-list-item-title>

              <v-list-item-icon>
                <v-icon color="blue-grey">mdi-map-marker</v-icon>
              </v-list-item-icon>

              <v-list-item-subtitle class="text-right">
                Edmonton, Alberta
              </v-list-item-subtitle>
            </v-list-item>
          </v-list>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'Home'
}
</script>

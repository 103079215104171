<template>
  <v-container>
    <v-row>
      <v-col
        v-for="(project, index) in projects"
        :key="index"
      >
        <v-card>
          <v-card-title>{{ project.name }}</v-card-title>
          <v-card-text>{{ project.description }}</v-card-text>
          <v-img
            :lazy-src="project.image"
            :src="project.image"
          ></v-img>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'Portfolio',
  data: () => ({
    projects: [
      {
        name: 'Point of Sale',
        image: require('@/assets/pos-site-dashboard-screen.png'),
        description: 'Restaurant Point of Sale system. Built with a Ruby on Rails server and Android client terminals. Print receipt functionality with Star Micronics thermal printers.'
      },
      {
        name: 'BeeCool Quote Portal',
        image: require('@/assets/beecool-quote-portal.png'),
        description: 'BeeCool Quote Portal. Built with Ruby on Rails. Provides features such as Customer Relationship Management and Quotes pertaining to the work required in a site location.'
      }
    ]
  })
}
</script>

<style>

</style>
